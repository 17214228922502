@import "https://fonts.googleapis.com/css2?family=Mynerve&display=swap";
@import "https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
}

body {
  grid-template-rows: minmax(5vh, max-content) minmax(5vh, max-content) 1fr 5vh minmax(10vh, max-content);
  gap: 2rem;
  font-family: Mynerve, cursive;
  display: grid;
}

.firework {
  z-index: -1;
  width: 100vw;
  height: 100vh;
  position: absolute;
}

header {
  text-align: center;
  background-color: #fbab7e;
  background-image: linear-gradient(62deg, #fbab7e 0%, #f7ce68 100%);
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  display: flex;
}

h1 {
  color: brown;
  font-size: 3rem;
}

p {
  color: #4169e1;
  max-width: 70vw;
  margin: 0 auto;
  font-family: Indie Flower, cursive;
  font-size: 2rem;
  font-weight: bolder;
}

.message {
  text-align: center;
}

.board {
  border: 2px solid #add8e6;
  border-radius: 10px;
  grid: repeat(3, 100px) / repeat(3, 100px);
  place-self: center;
  gap: 2px;
  width: max-content;
  height: max-content;
  display: grid;
  box-shadow: 0 0 10px 5px gray;
}

.cell {
  cursor: pointer;
  transition: transform 1s ease-in-out revert;
  background: #6495ed;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 2rem;
  display: flex;
}

.playerTurn {
  text-align: center;
  color: #cd5c5c;
  font-size: 2rem;
}

.btn-group {
  justify-self: center;
  gap: 10px;
}

button {
  color: #00f;
  cursor: pointer;
  background: #f5f5f5;
  border: 2px solid #000;
  border-radius: 5px;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: bolder;
}

footer {
  text-align: center;
  padding: 1rem;
}

/*# sourceMappingURL=index.e1e970ab.css.map */
