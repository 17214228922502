@import url('https://fonts.googleapis.com/css2?family=Mynerve&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    height: 100%;
}

body {
    font-family: 'Mynerve', cursive;
    display: grid;
    grid-template-rows: minmax(5vh, max-content) minmax(5vh, max-content) 1fr 5vh minmax(10vh, max-content);
    gap: 2rem;

}

.firework{
    position: absolute;
    height: 100vh;
    z-index: -1;
    width: 100vw;
}

header {
    text-align: center;
    background-color: #FBAB7E;
    background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

h1 {
    color: brown;
    font-size: 3rem;
}

p {
    font-size: 2rem;
    max-width: 70vw;
    margin: 0 auto;
    color: royalblue;
    font-family: 'Indie Flower', cursive;
    font-weight: bolder;
}
.message{
    text-align: center;
}
.board {
    display: grid;
    grid: repeat(3, 100px) / repeat(3, 100px);
    gap: 2px;
    border: 2px solid lightblue;
    border-radius: 10px;
    box-shadow: 0 0 10px 5px gray;
    width: max-content;
    height: max-content;
    align-self: center;
    justify-self: center;
}

.cell {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: cornflowerblue;
    cursor: pointer;
    border-radius: 5px;
    font-size: 2rem;
    transition: transform 1s ease-in-out revert;
}

.playerTurn{
    text-align: center;
    font-size: 2rem;
    color: indianred;
}
.btn-group{
    justify-self: center;
    gap: 10px;
}

button{
    padding: 0.5rem 1rem;
    border: 2px solid black;
    border-radius: 5px;
    background: whitesmoke;
    font-weight: bolder;
    color: blue;
    font-size: 1rem;
    cursor: pointer;
}

footer {
    text-align: center;
    padding: 1rem;
}

